import styled from "@emotion/styled";
import { Sheet } from "@mui/joy";
import { ModelBase } from "@nna/core";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { PlanDto, PlanQueryDto } from "~/common/plan/dtos";
import { UserDto } from "~/common/user/dtos";

import { planRepository } from "../../../services/api/repositories";
import { ROUTES } from "../../../services/routes";
import { ModalLayout } from "../../../utils/modal";
import { EmptyState } from "../../ui-atoms/components/EmptyState";
import { Icon } from "../../ui-atoms/components/Icons/Icon";
import {
	DataTableSortable,
	DataTableSortState,
} from "../../ui-atoms/components/data-table";
import {
	PAGINATION_PAGE_DEFAULT,
	PAGINATION_PAGESIZE_DEFAULT,
	PaginationFooter,
} from "../../ui-atoms/components/data-table/PaginationFooter";
import { pageToQuery } from "../../ui-atoms/utils/pagination";
import { Button, ButtonVariants } from "../../ui-form/components/Button/Button";
import { Input } from "../../ui-form/components/inputs/Input";
import { mainWrapper } from "../../ui-layout";
import { textH1 } from "../../ui-layout/styles/textStyles";
import { UserSelect } from "../../user/inputs/UserSelectInput";
import { PlansTable } from "../components";
import { PlanCreateModal } from "../modals/PlanCreateModal";

const HeaderFilter = styled.div`
	display: flex;
	flex-flow: row wrap;
	gap: 16px;
	padding: 24px;
`;
/**
 * Using a generic container, because for some mysterious reason,
 * `styled(Input)` won't forward the styling properly in production.
 */
const HeaderItem = styled.div`
	flex: 1;
`;

const QUERY_SORT_PREFIX = "sort.";
interface RouterQuery
	extends Record<
		`${typeof QUERY_SORT_PREFIX}${PlansTable.ColumnId}`,
		DataTableSortState.Direction
	> {
	counselors: string[] | string;
	search: string;
}

/** Template showing the customers and allowing their creation */
export function PlansTemplate() {
	const { t } = useTranslation();
	const router = useRouter();

	const query = router.query as Partial<RouterQuery>;
	const { search: $search = "" } = query;
	// We need to memo this query param, because otherwise default [] will trigger useEffect and such
	const queryCounselorRaw = useMemo(
		() => query.counselors ?? [],
		[query.counselors],
	);

	// Filter '$search'
	const [search, setSearch] = useState($search);
	const [searchDebounced] = useDebounce(search, 400);

	const [openPlanCreateModal, setOpenPlanCreateModal] = useState(false);

	useEffect(() => {
		// Update '$search' only after debounce time
		if ($search === searchDebounced) {
			return;
		}

		void router.replace({
			query: {
				...router.query,
				search: searchDebounced,
			} satisfies Partial<RouterQuery>,
		});
	}, [$search, searchDebounced, router]);

	const queryCounselorIds = useMemo(
		() =>
			(Array.isArray(queryCounselorRaw)
				? queryCounselorRaw
				: [queryCounselorRaw]
			)
				.map(id => +id)
				.filter(id => Number.isInteger(id)),
		[queryCounselorRaw],
	);
	const counselorsSelect = useMemo(
		() => queryCounselorIds.map(_id => ({ _id }) satisfies ModelBase),
		[queryCounselorIds],
	);

	function updateCounselors(counselors: UserDto[]) {
		void router.replace({
			query: {
				...query,
				counselors: counselors.map(({ _id }) => _id.toString()),
			} satisfies Partial<RouterQuery>,
		});
	}

	// Sorting
	const sorting = useMemo(
		() =>
			DataTableSortState.extractFromQuery(query, {
				columns: PlansTable.COLUMN_IDS,
				prefix: QUERY_SORT_PREFIX,
			}),
		[query],
	);
	function updateSorting(updated: typeof sorting) {
		const query = DataTableSortState.transformToQuery(
			updated,
			QUERY_SORT_PREFIX,
		);
		void router.replace({
			query: { search: $search, ...query } satisfies Partial<RouterQuery>,
		});
	}

	const pageSize = PAGINATION_PAGESIZE_DEFAULT;
	const [page, setPage] = useState(PAGINATION_PAGE_DEFAULT);
	useEffect(() => {
		// Move back to default page if any table filter / sorter params changes
		setPage(PAGINATION_PAGE_DEFAULT);
	}, [$search, sorting, queryCounselorIds, pageSize]);

	const queryDto = useMemo<PlanQueryDto>(
		() => ({
			$search,
			filter: {
				counselor: { _id: { $in: queryCounselorIds } },
			},
			order: sorting.flatMap(({ direction, id }) =>
				PlansTable.COLUMN_ORDERER[id](direction),
			),
			...pageToQuery(page, pageSize),
		}),
		[$search, queryCounselorIds, sorting, page, pageSize],
	);
	const { data } = useQuery(planRepository.findAndCount(queryDto));

	const columns = useMemo(
		() =>
			(
				[
					PlansTable.Columns.customers,
					PlansTable.Columns.year,
					PlansTable.Columns.counselor,
					PlansTable.Columns.updatedAt,
				] satisfies PlansTable.ColumnBuilder[]
			).map(build => build(t)),
		[t],
	);

	const goToPlan = useCallback(
		({ _id }: PlanDto) =>
			void router.push(ROUTES.plans.children.one.url(_id)),
		[router],
	);

	const PlansEmptyBody = () => (
		<EmptyState
			actions={
				<Button
					label={t("features.plan.add-plan")}
					onClick={() => setOpenPlanCreateModal(true)}
					type="button"
				/>
			}
			description={t("pages.plans.empty-description")}
			iconName="analyze"
			title={t("pages.plans.empty-title")}
		></EmptyState>
	);

	return (
		<mainWrapper.Container>
			<ModalLayout.Wrapper
				onClose={(_, reason) =>
					reason !== "backdropClick" && setOpenPlanCreateModal(false)
				}
				open={openPlanCreateModal}
			>
				<ModalLayout.Dialog data-testid="plan/modal/create">
					<PlanCreateModal onceCreated={goToPlan} />
				</ModalLayout.Dialog>
			</ModalLayout.Wrapper>

			<mainWrapper.Header data-testid="plans/header">
				<h1 css={textH1}>{t("entity.plan._meta.names")}</h1>

				<Button
					label={t("features.plan.add-plan")}
					onClick={() => setOpenPlanCreateModal(true)}
					type="button"
				/>
			</mainWrapper.Header>

			<mainWrapper.Content>
				<HeaderFilter>
					<HeaderItem>
						<Input
							data-testid="plans/search/input"
							endDecorator={
								search ? (
									<Button
										onClick={() => setSearch("")}
										startDecorator={<Icon name="close" />}
										type="button"
										variant={ButtonVariants.TEXT}
									/>
								) : undefined
							}
							onChange={({ target }) => setSearch(target.value)}
							placeholder={t("pages.plans.search")}
							startDecorator={<Icon name="search" />}
							value={search}
						/>
					</HeaderItem>

					<HeaderItem>
						<UserSelect<true>
							multiple
							onChange={(_, values) => updateCounselors(values)}
							value={counselorsSelect}
						/>
					</HeaderItem>
				</HeaderFilter>

				<Sheet sx={{ overflow: "auto" }}>
					<DataTableSortable
						$hover={!!data?.data.length}
						$stickyFirstColumn
						$stripe
						allowMultiSorting={false}
						columns={columns}
						data={(data?.data ?? []).map(item => ({
							data: item,
							key: item._id,
						}))}
						emptyBody={{
							content: PlansEmptyBody,
							mode: "full",
						}}
						onRowClick={goToPlan}
						onSortingChange={updateSorting}
						sorting={sorting}
						stickyHeader
						tfoot={() => (
							<PaginationFooter
								colSpan={columns.length}
								onPageChange={setPage}
								page={page}
								pageSize={pageSize}
								showControlsOnlyWhenNeeded
								total={data?.pagination.total ?? 0}
							/>
						)}
					/>
				</Sheet>
			</mainWrapper.Content>
		</mainWrapper.Container>
	);
}
