import { OmitKnown } from "@nna/core";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import { CustomerDto, CustomerQueryDto } from "~/common/customer/dtos";

import { customerRepository } from "../../../services/api/repositories";
import { PeopleNameWithAvatar } from "../../people/components";
import { ModelSelects } from "../../ui-form/components";
import { AutocompleteOption } from "../../ui-form/components/AutoComplete";

/** Existing filter object */
export type CustomerSelectQueryFilter = NonNullable<CustomerQueryDto["filter"]>;

/** Query data when searching for customers */
export interface CustomerSelectQuery
	extends Pick<CustomerQueryDto, "limit" | "order"> {
	/** Complete the filter property of the executed request */
	filter?: (search: string) => CustomerSelectQueryFilter;
}

/** Props for {@link CustomerSelect} */
export interface CustomerSelectProps<
	Multiple extends boolean | undefined = false,
	DisableClearable extends boolean | undefined = false,
> extends OmitKnown<
		ModelSelects.ModelSelectProps<CustomerDto, Multiple, DisableClearable>,
		"queryOptions" | "queryValues"
	> {
	query?: CustomerSelectQuery;
}

/** Input for customer search & select */
export function CustomerSelect<
	Multiple extends boolean = false,
	DisableClearable extends boolean = false,
>(props: CustomerSelectProps<Multiple, DisableClearable>) {
	const { query = {}, ...selectProps } = props;

	const { t } = useTranslation();

	const queryOptions = useMemo<
		ModelSelects.ModelSelectQueryOptions<CustomerDto>
	>(() => {
		const {
			filter = () => ({}),
			limit = 15,
			order = [{ name: { first: "asc" } }, { name: { last: "asc" } }],
		} = query;

		return $search =>
			customerRepository.findAndCount({
				$search,
				filter: filter($search),
				limit,
				order,
			});
	}, [query]);
	const queryValues = useCallback<
		ModelSelects.ModelSelectQueryValues<CustomerDto>
	>(
		ids =>
			customerRepository.findAndCount({
				filter: { _id: { $in: ids.slice() } },
			}),
		[],
	);

	return (
		<ModelSelects.ModelSelect
			placeholder={t("features.customer.select-customer")}
			{...selectProps}
			getOptionLabel={({ name }) =>
				[name.first, name.last].filter(str => !!str).join(" ")
			}
			queryOptions={queryOptions}
			queryValues={queryValues}
			renderOption={(props, { _id, name }) => (
				<AutocompleteOption
					{...props}
					key={_id}
					data-testid={`customer/select/${_id}`}
				>
					<PeopleNameWithAvatar name={name} />
				</AutocompleteOption>
			)}
		/>
	);
}
