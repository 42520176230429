import { PlansTemplate } from "../../features/plan/templates/PlansTemplate";
import { MainLayout } from "../../features/ui-layout/MainLayout";

/** Page to list all plans */
export default function PlansPage() {
	return (
		<MainLayout>
			<PlansTemplate />
		</MainLayout>
	);
}

export { getServerSideProps } from "../../services/server-side.props";
