import { CustomersTable } from "../../../../customer/components";

/** Props for {@link CounselorCell} */
export type CounselorCellProps = CustomersTable.Cells.CounselorCellProps;
/**
 * Component to view a counselor in a (table) cell
 *
 * @see CustomersTable.Cells.CounselorCell
 * @param props to generate the component
 */
export function CounselorCell(props: CounselorCellProps) {
	return (
		<CustomersTable.Cells.CounselorCell
			{...props}
			data-testid="plans/cell/counselor"
		/>
	);
}
