import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { PlanDto } from "~/common/plan/dtos";

import { PlanCreation } from ".";
import { planRepository } from "../../../services/api/repositories";
import { ModalCloseButton, ModalLayout } from "../../../utils/modal";
import { AuthService } from "../../auth/AuthService";
import { TableCustom } from "../../ui-atoms/components/TableCustom";
import { Button } from "../../ui-form/components/Button/Button";
import { FormikWithI18nSchema } from "../../ui-form/components/Formik/FormikWithI18nSchema";

/** Props for {@link PlanCreateModal} */
export interface PlanCreateModalProps {
	/** Callback once the customer has been created */
	onceCreated: (created: PlanDto) => void;
}
/** Modal content used when creating a plan */
export function PlanCreateModal(props: PlanCreateModalProps) {
	const { onceCreated } = props;

	const { t } = useTranslation();
	const { user } = AuthService.useGetConnectedProfile();

	const { mutate } = useMutation({
		...planRepository.create,
		onSuccess: (data: PlanDto) => {
			onceCreated(data);
		},
	});

	return (
		<FormikWithI18nSchema<typeof PlanCreation.planCreateFormSchema>
			initialValues={{
				fkCounselor: user._id,
				fkCustomer: undefined,
				fkCustomer2: null,
				observationTime: 20,
				type: "1",
				year: new Date().getFullYear(),
			}}
			onSubmit={toCreate => mutate([toCreate])}
			schema={PlanCreation.planCreateFormSchema}
		>
			{({ submitForm }) => (
				<>
					<ModalLayout.Header>
						<ModalLayout.HeaderTitle>
							{t("features.plan.add-plan")}
						</ModalLayout.HeaderTitle>

						<ModalLayout.HeaderActions data-testid="plan/modal/create/header-actions">
							<ModalCloseButton />
							<Button
								label={t("common.action.create")}
								onClick={() => void submitForm()}
								type="submit"
							/>
						</ModalLayout.HeaderActions>
					</ModalLayout.Header>

					<ModalLayout.Content>
						<TableCustom $hover $stripe $tableFormLayout>
							<PlanCreation.TableBody />
						</TableCustom>
					</ModalLayout.Content>
				</>
			)}
		</FormikWithI18nSchema>
	);
}
