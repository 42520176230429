import { TFunction } from "next-i18next";
import { CustomerWithPlansDto } from "~/common/customer/dtos";
import { PlanDto } from "~/common/plan/dtos";

import * as Cells from "./cells";
import { TimeDiff } from "../../../../utils";
import { DataTableSortColumn } from "../../../ui-atoms/components/data-table";

// Base type for raw definition creation
type ColumnBase<K extends string> = DataTableSortColumn<PlanDto, K>;
type ColumnBaseBuilder<K extends string> = (t: TFunction) => ColumnBase<K>;

/** Base column definition for {@link Columns} */
const columnsBase = {
	// `id` is filled below with its key

	counselor: t => ({
		cell: ({ counselor }) => Cells.CounselorCell({ counselor }),
		header: () => t("entity.customer.counselor"),
		id: "",
	}),
	customers: t => ({
		cell: ({ customer, customer2 }) =>
			Cells.CustomersCell({
				customers: customer2 ? [customer, customer2] : [customer],
			}),
		header: () => t("entity.plan._list.customers"),
		id: "",
	}),
	updatedAt: t => ({
		cell: ({ updatedAt }) => TimeDiff.Relative({ date: updatedAt }),
		header: () => t("entity.plan._list.last-change"),
		id: "",
	}),
	year: t => ({
		cell: ({ year }) => year,
		header: () => t("entity.plan.year"),
		id: "",
	}),
} as const satisfies Record<string, ColumnBaseBuilder<"">>;

type ColumnsBase = typeof columnsBase;

/** Id of a plan column */
export type ColumnId = keyof ColumnsBase;
/** Known column Ids */
export const COLUMN_IDS = Object.keys(columnsBase) as readonly ColumnId[];

/** Definition of a plan column */
export type ColumnDefinition<K extends ColumnId = ColumnId> =
	DataTableSortColumn<CustomerWithPlansDto, K>;
/** Builder (with translation `t`) for a plan column */
export type ColumnBuilder<K extends ColumnId = ColumnId> = ColumnBaseBuilder<K>;

/** Type for {@link Columns} record definition*/
export type Columns = { [K in ColumnId]: ColumnBuilder<K> };
/**
 * Known plan columns definition to use for "plan-table".
 *
 * To be used as a configuration for the table
 *
 * @example
 * const columns = (short ? [Columns.plan] : [Columns.plan, Columns.email])
 * 	.map(build => build(t));
 * return (<Table columns=[columns]) />;
 */
export const Columns = Object.fromEntries(
	Object.entries<ColumnsBase[ColumnId]>(columnsBase).map(([id, def]) => [
		id,
		((...params) => ({
			...def(...params),
			id,
		})) satisfies ColumnBaseBuilder<string>,
	]),
) as Columns;
