import * as z from "zod";
import { PlanCreateDto, planCreateDtoSchema } from "~/common/plan/dtos";

const base = planCreateDtoSchema.omit({ fkCustomer2: true });

/**
 * Discriminated key of {@link planCreateFormSchema}.
 * For the "type of plan" to create
 */
export const PLAN_CREATE_DISCRIMINATED_KEY = "type";

/** Validation schema for {@link PlanCreateFormValues} */
export const planCreateFormSchema = z
	.discriminatedUnion(PLAN_CREATE_DISCRIMINATED_KEY, [
		base.extend({
			[PLAN_CREATE_DISCRIMINATED_KEY]: z.literal("1"),
			/** Accept anything, but will be transformed to null */
			fkCustomer2: z
				.any()
				.optional()
				.transform(() => null),
		}),
		base.extend({
			[PLAN_CREATE_DISCRIMINATED_KEY]: z.literal("2"),
			/** Must be present */
			fkCustomer2: planCreateDtoSchema.shape.fkCustomer2.unwrap(),
		}),
	])
	// Remove the type
	.transform(({ type: _, ...y }) => y satisfies PlanCreateDto);

/** The Form value when creating a plan */
export type PlanCreateFormValues = z.input<typeof planCreateFormSchema>;
/** Known type of {@link PlanCreateFormValues} */
export type PlanCreateFormType = PlanCreateFormValues["type"];
