import { OmitKnown } from "@nna/core";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";

import {
	CustomerSelectOneField,
	CustomerSelectOneFieldProps,
} from "./CustomerSelectOneField";
import { customerRepository } from "../../../services/api/repositories";
import { PeopleNameWithAvatar } from "../../people/components";
import { FormTable, Tooltips } from "../../ui-atoms/components";
import { ModelSelects } from "../../ui-form/components";

/** Props for {@link CustomerSelectOneCellField} */
export interface CustomerSelectOneCellFieldProps<
	T extends object | string,
	DisableClearable extends boolean = false,
> extends OmitKnown<FormTable.TableCellProps, "children" | "whenFocused">,
		Pick<CustomerSelectOneFieldProps<T, DisableClearable>, "name"> {
	/** Is the input disabled (and thus the focus mode) */
	disabled?: boolean;
	/** Text to show when empty */
	placeholder?: string;
	/** Override select props */
	select?: OmitKnown<
		CustomerSelectOneFieldProps<T, DisableClearable>,
		"name"
	>;
}

/** Wraps {@link CustomerSelectOneField} to use as a "table-cell-input" */
export function CustomerSelectOneCellField<
	T extends object | string,
	DisableClearable extends boolean = false,
>(props: CustomerSelectOneCellFieldProps<T, DisableClearable>) {
	// TODO: a ModelSelectOneCellField component (or hook) should be doable

	const {
		disabled,
		name,
		placeholder,
		select: selectProps,
		...cellProps
	} = props;

	const [{ value = null }, { error }] = useField<number | null>(name);
	// Get the currently selected data
	const { data = null } = useQuery({
		...customerRepository.findById({ customerId: value || -1 }),
		enabled: !!value,
	});

	return (
		<FormTable.FocusableTableCell
			{...cellProps}
			whenFocused={
				<CustomerSelectOneField
					autoFocus
					{...selectProps}
					$applyCellStyles
					name={name}
					sx={ModelSelects.Styles.modelSelectCell}
				/>
			}
		>
			<FormTable.FormCellInnerWrapper disabled={disabled} error={!!error}>
				<FormTable.UnfocusedGenericValue
					disabled={disabled}
					placeholder={placeholder}
				>
					{data && <PeopleNameWithAvatar name={data.name} />}
				</FormTable.UnfocusedGenericValue>

				{error ? <Tooltips.Error title={error} /> : null}
			</FormTable.FormCellInnerWrapper>
		</FormTable.FocusableTableCell>
	);
}
