import styled from "@emotion/styled";
import { CustomerDto } from "~/common/customer/dtos";

import { PeopleNameWithAvatar } from "../../../../people/components";

const CellContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

/** Props for {@link CustomersCell} */
export interface CustomersCellProps {
	customers: ReadonlyArray<Pick<CustomerDto, "_id" | "name">>;
}
/**
 * Components that show the customers of a plan in a (table) cell
 *
 * @param props to generate the component
 */
export function CustomersCell(props: CustomersCellProps) {
	const { customers } = props;

	return (
		<CellContainer data-testid="plans/cell/customers">
			{customers.map(({ _id, name }) => (
				<PeopleNameWithAvatar key={_id} name={name} />
			))}
		</CellContainer>
	);
}
