import { PlanQueryDto } from "~/common/plan/dtos";

import { ColumnId } from "./columns";
import { DataTableSortState } from "../../../ui-atoms/components/data-table";

/** Dict of "order-queries" that can result from an order from a sorted column */
export const COLUMN_ORDERER: Readonly<
	Record<
		ColumnId,
		(
			order: DataTableSortState.Direction,
		) => NonNullable<PlanQueryDto["order"]>
	>
> = {
	counselor: order => [
		// First-name then last-name
		{ counselor: { name: { first: order } } },
		{ counselor: { name: { last: order } } },
	],
	customers: order => [
		{ customer: { name: { first: order } } },
		{ customer: { name: { last: order } } },
		{ customer2: { name: { first: order } } },
		{ customer2: { name: { last: order } } },
	],
	updatedAt: order => [{ updatedAt: order }],
	year: order => [{ year: order }],
};
